<template>
  <div id="tags-view-container" class="tags-view-container">
    <!--   <scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="isActive(tag)?'active':''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        :style="activeStyle(tag)"
        @click.middle.native="!isAffix(tag)?closeSelectedTag(tag):''"
        @contextmenu.prevent.native="openMenu(tag,$event)"
      >
        {{ tag.title }}
        <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
      </router-link>
    </scroll-pane> -->
    <div class="left-icon icon" v-if="showLeftIcon" @click="handleLeftClick">
      <i class="el-icon-d-arrow-left"></i>
    </div>
    <div class="tags-list" ref="tagsList">
      <div class="content" ref="content">
        <!--  <span class="tags-item" v-for="item in  200" :key="item">{{ item }}</span>
        <span class="tags-item">222</span> -->
        <router-link v-for="tag in visitedViews" ref="tag" :key="tag.path" :class="isActive(tag) ? 'active' : ''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }" tag="span" class="tags-item"
          :style="activeStyle(tag)" @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
          @contextmenu.prevent.native="openMenu(tag, $event)">
          <!-- <span v-if="tag.title !== '首页'">{{ tag.title }}</span>
          <i v-else class="el-icon-house"></i> -->
          <span>{{ tag.title }}</span>
          <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
        </router-link>
      </div>
    </div>
    <div class="left-icon icon" v-if="showRightIcon" @click="handleRightClick">
      <i class="el-icon-d-arrow-right"></i>
    </div>
    <span class="tags-btn" @click.stop.self="handleTagBtns">
      <i class="el-icon-arrow-down" @click.stop.self="handleTagBtns"></i>
      <span class="handleTags" v-show="handleTagBtn">
        <div class="tab-btns">
          <div class="tab-btn" @click="closeSelectedTag(selectedTag)">关闭当前页</div>
          <div class="tab-btn" @click="closeOthersTags">关闭其他页</div>
          <div class="tab-btn" @click="closeAllTags(selectedTag)">关闭全部页</div>
        </div>
      </span>
    </span>
    <!-- 放大窗口 -->
    <screenfull id="screenfull" class="right-menu-item hover-effect" />
    <!--  <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
         <li @click="refreshSelectedTag(selectedTag)"><i class="el-icon-refresh-right"></i> 刷新页面</li>
         <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)"><i class="el-icon-close"></i> 关闭当前</li>
         <li @click="closeOthersTags"><i class="el-icon-circle-close"></i> 关闭其他</li>
         <li v-if="!isFirstView()" @click="closeLeftTags"><i class="el-icon-back"></i> 关闭左侧</li>
         <li v-if="!isLastView()" @click="closeRightTags"><i class="el-icon-right"></i> 关闭右侧</li>
         <li @click="closeAllTags(selectedTag)"><i class="el-icon-circle-close"></i> 全部关闭</li>
       </ul> -->
  </div>
</template>

<script>
import LeftRightSwiperScroll from "./leftRightSwiperScroll.vue"; //横向滚动组件超出显示箭头按钮
import Screenfull from "@/components/Screenfull";
import ScrollPane from "./ScrollPane";
import path from "path";

export default {
  name: "TagView",
  components: { ScrollPane, Screenfull, LeftRightSwiperScroll },
  data() {
    return {
      visible: true,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      showRightIcon: false, // 是否显示右箭头
      childrenList: [],
      clickNum: 0, //滚动的点击事件
      transform: 0, //滚动的值
      tagsListWidth: 0, //盒子宽度
      contentWidth: 0, //内容宽度
      handleTagBtn: false, //控制标签按钮
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes() {
      return this.$store.state.permission.routes;
    },
    theme() {
      return this.$store.state.settings.theme;
    },
    //当滚动宽度大于0,显示左箭头
    showLeftIcon() {
      return this.transform > 0;
    },
  },
  watch: {
    $route() {
      // console.log('路由', this.$store.state.tagsView.cachedViews)

      this.addTags();
      this.moveToCurrentTag();
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
    visitedViews: {
      handler() {
        this.getBoxWidth();
        this.contentBoxRoll();
      },
    },
  },
  mounted() {
    let that = this;
    this.initTags();
    this.addTags();
    this.getBoxWidth();
    document.addEventListener("click", function (e) {
      if (e.target.className) that.hideBtn();
    });
  },
  methods: {
    //点击其他区域,操作标签按钮隐藏
    hideBtn() {
      this.handleTagBtn = false;
    },
    handleTagBtns() {
      this.handleTagBtn = true;
      console.log("进来", this.handleTagBtn);
    },
    //每次跳转页面标签 当前获得焦点的标签滚动到可视区
    contentBoxRoll() {
      this.$nextTick(() => {
        let dom = document.querySelector(".tags-item.router-link-exact-active");
        if (dom) {
          let domWidth = dom.getBoundingClientRect().width;
          let rollingWidth = dom.offsetLeft + domWidth + 36 - this.tagsListWidth;
          if (rollingWidth > 0) {
            this.$refs.content.style.transform = `translate3d(${-rollingWidth}px, 0px, 0px)`;
            this.transform = rollingWidth;
          }
        }
      });
    },
    //获取标签大盒子宽度 获取标签内容宽度 对比 内容宽度超出大盒子宽度显示滚动箭头
    getBoxWidth() {
      // 获取 HTMLCollection 转为 数组
      this.childrenList = [...this.$refs.content.children];
      // 大盒子的宽度
      this.tagsListWidth = this.$refs.tagsList.getBoundingClientRect().width;
      // 内容的宽度
      this.contentWidth = this.$refs.content.getBoundingClientRect().width;
    },
    // 点击右箭头
    handleRightClick() {
      // 大盒子的宽度
      this.tagsListWidth = this.$refs.tagsList.getBoundingClientRect().width;
      // 内容的宽度
      this.contentWidth = this.$refs.content.getBoundingClientRect().width;
      if (this.contentWidth - (this.tagsListWidth + this.transform) < 40)
        return (this.showRightIcon = false);
      //当内容区域宽度大于盒子宽度
      let rollingWidth;
      if (this.contentWidth > this.tagsListWidth) {
        let distanceWidth = this.contentWidth - this.transform - this.tagsListWidth * 2;
        if (distanceWidth > 0) {
          //当内容宽度大于盒子的两倍时
          //滚动的宽度  盒子宽度80% + 累加已滚动的宽度
          rollingWidth = this.tagsListWidth * 0.8 + this.transform;
          this.$refs.content.style.transform = `translate3d(${-rollingWidth}px, 0px, 0px)`;
          this.transform = rollingWidth;
        } else {
          //当内容宽度小于盒子的两倍时
          //滚动宽度 内容宽度 - 累加已滚动的宽度 - 盒子的宽度 + 累加已滚动的宽度
          rollingWidth =
            this.contentWidth - this.transform - this.tagsListWidth + this.transform;
          this.$refs.content.style.transform = `translate3d(${-rollingWidth}px, 0px, 0px)`;
          this.transform = rollingWidth;
        }
      }
    },
    // 点击左箭头
    handleLeftClick() {
      let distanceWidth = this.transform - this.tagsListWidth;
      if (distanceWidth > 0) {
        //当已滚动的宽度大于盒子宽度
        // 滚动的宽度  已滚动的宽度 - 盒子宽度80%
        let rollingWidth = this.transform - this.tagsListWidth * 0.8;
        this.$refs.content.style.transform = `translate3d(${-rollingWidth}px, 0px, 0px)`;
        this.transform = rollingWidth;
      } else {
        //当已滚动的宽度小于盒子宽度
        // 滚动的宽度为0
        this.$refs.content.style.transform = `translate3d(${0}px, 0px, 0px)`;
        this.transform = 0;
      }
      this.showRightIcon = true;
    },
    isActive(route) {
      if (route.path === this.$route.path) this.selectedTag = route;
      return route.path === this.$route.path;
    },
    activeStyle(tag) {
      if (!this.isActive(tag)) return {};
      return {
        "background-color": this.theme,
        "border-color": this.theme,
      };
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    isFirstView() {
      try {
        return (
          this.selectedTag.fullPath === this.visitedViews[1].fullPath ||
          this.selectedTag.fullPath === "/index"
        );
      } catch (err) {
        return false;
      }
    },
    isLastView() {
      try {
        return (
          this.selectedTag.fullPath ===
          this.visitedViews[this.visitedViews.length - 1].fullPath
        );
      } catch (err) {
        return false;
      }
    },
    filterAffixTags(routes, basePath = "/") {
      let tags = [];
      routes.forEach((route) => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta },
          });
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags];
          }
        }
      });
      return tags;
    },
    initTags() {
      const affixTags = (this.affixTags = this.filterAffixTags(this.routes));
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch("tagsView/addVisitedView", tag);
        }
      }
    },
    addTags() {
      const { name } = this.$route;
      // console.log('点击名称', this.$route)
      if (name) {
        this.$store.dispatch("tagsView/addView", this.$route);
      }
      return false;
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            // this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch("tagsView/updateVisitedView", this.$route);
            }
            break;
          }
        }
      });
    },
    refreshSelectedTag(view) {
      this.$tab.refreshPage(view);
    },
    //关闭页面
    closeSelectedTag(view) {
      this.$tab.closePage(view).then(({ visitedViews }) => {
        //检查当前页面是否处于活动状态（即当前显示的页面）。如果当前页面是活动状态，它会调用  this.toLastView(visitedViews, view)  方法来切换到上一个页面。
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view);
        }
      });
    },
    closeRightTags() {
      this.$tab.closeRightPage(this.selectedTag).then((visitedViews) => {
        if (!visitedViews.find((i) => i.fullPath === this.$route.fullPath)) {
          this.toLastView(visitedViews);
        }
      });
    },
    closeLeftTags() {
      this.$tab.closeLeftPage(this.selectedTag).then((visitedViews) => {
        if (!visitedViews.find((i) => i.fullPath === this.$route.fullPath)) {
          this.toLastView(visitedViews);
        }
      });
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag).catch(() => { });
      this.$tab.closeOtherPage(this.selectedTag).then(() => {
        this.moveToCurrentTag();
      });
      this.handleTagBtn = false;
    },
    closeAllTags(view) {
      this.$tab.closeAllPage().then(({ visitedViews }) => {
        if (this.affixTags.some((tag) => tag.path === this.$route.path)) {
          return;
        }
        this.toLastView(visitedViews, view);
      });
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        let path = this.$store?.state?.permission?.previousRoutePath
        // console.log('visitedViews', visitedViews);
        // console.log('latestView', latestView);
        // console.log('previousRoutePath', path);
        if (latestView.title == '首页') {
          this.$router.push("/");
        } else if (visitedViews.some(x => x.path == path)) {
          this.$router.go(-1)
        } else {
          this.$router.push(latestView.fullPath);
        }
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({ path: "/redirect" + view.fullPath });
        } else {
          this.$router.push("/");
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }

      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    },
    handleScroll() {
      this.closeMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 35px;
  width: 100%;
  min-width: 1500px;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  .tags-list {
    width: calc(100% - 70px);
    height: 35px;
    flex-grow: 1;
    overflow: hidden;
    transition: all 0.3s;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .content {
    display: inline-block;
    white-space: nowrap;
    transition: all 0.3s;
  }
  .icon {
    width: 40px;
    text-align: center;
    border: 1px solid #e8e8e8;
    height: 36px;
    line-height: 36px;
  }
  .tags-item {
    padding: 9px 15px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    &.active {
      color: #fff;
      background-color: #409eff;
    }
    .el-icon-close {
      margin-left: 10px;
      padding: 0 5px;
    }
  }
  .tags-btn {
    cursor: pointer;
    text-align: center;
    border: 1px solid #e8e8e8;
    padding: 8px 11px;
    position: relative;
    transform: scale(1, 1);
    .handleTags {
      /*   position: fixed;
      margin-top: 36px;
      margin-right: 0; */
      position: absolute;
      top: 36px;
      right: 0;
      width: 130px;
      height: 100px;

      .tab-btns {
        position: fixed;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        width: 130px;
        line-height: 35px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
        font-size: 14px;
        z-index: 777777;
      }
      .tab-btn:hover {
        color: #409eff;
      }
    }
  }
  .handleTagBtn {
    width: 36px;
    height: 36px;
  }
  /*  .el-icon-arrow-down {
    width: 35px;
    height: 35px;
    border: 1px solid #e8e8e8;
    z-index: 9;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .right-menu-item {
    width: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: "";
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
