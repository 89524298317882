var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _vm.showLeftIcon
        ? _c(
            "div",
            {
              staticClass: "left-icon icon",
              on: { click: _vm.handleLeftClick },
            },
            [_c("i", { staticClass: "el-icon-d-arrow-left" })]
          )
        : _vm._e(),
      _c("div", { ref: "tagsList", staticClass: "tags-list" }, [
        _c(
          "div",
          { ref: "content", staticClass: "content" },
          _vm._l(_vm.visitedViews, function (tag) {
            return _c(
              "router-link",
              {
                key: tag.path,
                ref: "tag",
                refInFor: true,
                staticClass: "tags-item",
                class: _vm.isActive(tag) ? "active" : "",
                style: _vm.activeStyle(tag),
                attrs: {
                  to: {
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath,
                  },
                  tag: "span",
                },
                nativeOn: {
                  mouseup: function ($event) {
                    if ("button" in $event && $event.button !== 1) {
                      return null
                    }
                    !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                  },
                  contextmenu: function ($event) {
                    $event.preventDefault()
                    return _vm.openMenu(tag, $event)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(tag.title))]),
                !_vm.isAffix(tag)
                  ? _c("span", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.closeSelectedTag(tag)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          1
        ),
      ]),
      _vm.showRightIcon
        ? _c(
            "div",
            {
              staticClass: "left-icon icon",
              on: { click: _vm.handleRightClick },
            },
            [_c("i", { staticClass: "el-icon-d-arrow-right" })]
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "tags-btn",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleTagBtns($event)
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-arrow-down",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.handleTagBtns($event)
              },
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.handleTagBtn,
                  expression: "handleTagBtn",
                },
              ],
              staticClass: "handleTags",
            },
            [
              _c("div", { staticClass: "tab-btns" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-btn",
                    on: {
                      click: function ($event) {
                        return _vm.closeSelectedTag(_vm.selectedTag)
                      },
                    },
                  },
                  [_vm._v("关闭当前页")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-btn",
                    on: { click: _vm.closeOthersTags },
                  },
                  [_vm._v("关闭其他页")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-btn",
                    on: {
                      click: function ($event) {
                        return _vm.closeAllTags(_vm.selectedTag)
                      },
                    },
                  },
                  [_vm._v("关闭全部页")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("screenfull", {
        staticClass: "right-menu-item hover-effect",
        attrs: { id: "screenfull" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }