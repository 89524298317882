
//输入框自动获取焦点 v-focus

export default {
  // 指令的定义
  inserted (el) {
    // 聚焦元素
    if (el.querySelector('input')) {
      el.querySelector('input').focus()
    }
  }
}
