var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "swiperScroll", staticClass: "left-right-swiper-scroll-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLeftIcon,
              expression: "showLeftIcon",
            },
          ],
          staticClass: "scroll-icon left-icon",
          on: { click: _vm.handleLeftClick },
        },
        [_c("i", { staticClass: "el-icon-d-arrow-left" })]
      ),
      _c(
        "div",
        { ref: "swiperScrollContent", staticClass: "swiper-scroll-content" },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRightIcon,
              expression: "showRightIcon",
            },
          ],
          staticClass: "scroll-icon right-icon",
          on: { click: _vm.handleRightClick },
        },
        [_c("i", { staticClass: "el-icon-d-arrow-right" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }